import React, { Component } from "react";
import PropTypes from "prop-types";

import rockIcon from '../assets/ares128.png';
import nfoIcon from '../assets/infoMini.png';



class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this;

    let className = "tab-list-item";

    if (activeTab === label) {
      className += " tab-list-active";
    }

    return (
      <li className={className} onClick={onClick}>
        {label === "ARES" ?  <img alt="Ares" src={rockIcon} height="24px" /> : (
             label === "*" ?    <img alt="Info" src={nfoIcon} height="24px"/> : label
         ) }
      </li>
    );

  }
}

export default Tab;
