import React, { useState } from 'react';
import classes from './Profile.module.css';


import { xBalance } from "../X";


const Options = (props) => {

  const [inputValue, setInputValue] = useState('');

  const inputChangeHandler = (event) => {

    event.preventDefault();

    let i = Math.round(event.target.value)
    if (i === 0) {
      setInputValue("");
    } else {
      setInputValue(i);
    }

    props.inputHandler(event.target.value);
  };


  setInputValue( props.balances[xBalance.digFactor]);

  return (
    <div className={classes.Info}>

      <p><br/></p>


      <div className={classes.inputDiv}>
        <input
          placeholder="Promille"
          className={classes.input}
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
      </div>

      <button
        className={classes.blockButton}
        onClick={() => {
          props.actionHandler();
          setInputValue('');
        }}
      >
       Save
      </button>


      <p><br/></p>
      <div className={classes.box}>
      <p>Setting your digging Factor raise or reduce your digging premium.</p>
      <div><p>
        You are always free to change this here.
        <br/>
        <br/>
        Min is 0, max is 1000. Both are promille of your premium.
        <br/>
        </p>
      </div>
      </div>

    </div>
  );
};
//My balance: 504304.394968082 TestToken (Tst)
export default Options;
