import React from 'react';
import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';

import { xBalance } from '../X';

//import diamondIcon from '../assets/diamond500.png';


const Pay = (props) => {


  const rc = (p,d,c) => { let a = p / 10**d; let i = Math.floor(a); let f = a-i; return ( i.toLocaleString('en-US') + f.toFixed(c).toString().substring(1) )  }
  const rcPriceX = (p) =>      {      return  rc( p, props.rpriceDecimals,8).replace(/0+$/,"").replace(/\.$/,"");    }

//balances[xBalance.summarizedAPY]

  return (
    <div className={classes.infologo}>
    <div className={classes.header}>
      <p><br/></p>
    </div>
    <div className={classes.Info}>

    { !props.info.isProtected ?

      <div className={classes.boxHigh}>
        <p> Your Earnings:</p>
        <p> <b>{props.balances ? rcPriceX(props.payoutValue).toLocaleString('en-US') : "n/a" }</b> {props.rpriceSymbol}</p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>

      :
      <div className={classes.box}>
        <p> You are in protected mode (hidden balances). <br/>So you cant see here, whats your earnings, but feel free to pay out.</p>
        <p> Of course, you can switch back to unprotected mode and back as often you want. </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>

    }

      <button
        className={classes.digButton}
        disabled={ !props.balances || (props.payoutValue < 1 && !props.info.isProtected) || !props.friendExists}
        onClick={() => {
          props.actionHandler();
        }}
        >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>{ props.friendExists ?  "Cash Out" : "You need an invitation!" }</p>
      </button>


      <div className={classes.boxHigh}>
      <p> Your historical Payouts:</p>
        <p> <b>{props.balances ? rcPriceX(props.payoutRealized).toLocaleString('en-US') : "n/a" }</b> {props.rpriceSymbol}</p>
        <br/>
        <br/>
      </div>

      <p><br/></p>

      <div className={classes.box}>
      <p>Cash your earnings out.</p>
      <div><p>
        You decide when it is payday. Cash out direct to your Wallet.
        <br/>
        <br/>
        </p>
      </div>
      </div>

    </div>
    </div>
  );
};

export default Pay;
