import React, { useState } from 'react';

//import { QRCode } from 'react-qrcode-logo';
//import copyicon from '../assets/copymini.png';


import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';
//import rockIcon from '../assets/rock500.png';


import { xNullAddress, xUSDTAddress, xUSDZAddress } from "../X";


const Dig = (props) => {

  const [inputValue, setInputValue] = useState('');
  const [inputCount, setInputCount] = useState('');

  //let pC = props.priceCurrency;
  const pC = window.rckCurrency;


  const nano = (p) =>  {      return Math.round(p * 10**(9-18));    }

  const priceRound = () => {  return Math.round( 100000*( props.price / 10**props.priceDecimals) )/100000  }

  const inputChangeHandler = (event) => {

    event.preventDefault();
//console.log(event.target.value);
    let i = event.target.value;

    if (  /^[0-9]*[.]?[0-9]*$/.test(i) || i === "") {
      let f = parseFloat(i);

      let j = getMax();

  //    console.log(event.target.value,i,f,j);
      if (f > j) {
        i = j;
        f = j;
      }

      if (i<0) {
        i=0;
        f=0;
      }
      setInputValue(i);

      let cnt = Math.floor( 1000 * (f/digEst) / (props.price / 10**props.priceDecimals) ) / 1000;
      console.log(f,"=>",cnt);
      setInputCount(cnt);

      props.inputHandler(cnt);
    } else {
      setInputValue("");
    }
  };

  const getMax = () => {

    const plus = 10; // %

    let gasLimit = 1200000; //"0x1D4C0";
    if (props.digged) {
      gasLimit  = 600000;
    }

    /*
      WARNING: THIS INCLUDES MUCH OVERHEAD TO PREVENT OUT OF BALANCE
    */
    const upGasPrice = props.gasPrice > 40 ? parseInt(props.gasPrice) * 1.5 : (
            props.gasPrice > 20 * 10**9 ? parseInt(props.gasPrice) * 1.7 :
              parseInt(props.gasPrice) * 2
          );

    const upCosts = (parseInt(upGasPrice) + 10*10**9) * gasLimit;

//    console.log("getmax",props.gasPrice,gas, plus, costs, props.userBalance,  props.priceDecimals, props.price, pC,digEst);

    console.log("costs: gas ",upCosts,upGasPrice,gasLimit);

    // FIXME: if ethereum, add costs !!
    if (pC === xNullAddress ) {

      let mx = props.userBalance * 10**18 - upCosts;

      console.log( "clcmax",mx   );
      return Math.floor(  mx     / 10**(18-3) ) / 10**3; /// 10**props.priceDecimals) * (10**6 )));
    }
    console.log( "clcmax token");

    let mx = props.userBalance * 10**props.priceDecimals;

    return Math.floor( mx ) / 10**props.priceDecimals; // * (10**6 )));

  }

  const goMaximum = () => {

    console.log("max");

    let i = getMax();

    console.log("get max",i);

    if (i <= 0.001) {
       i = 0;
       setInputValue("");
     } else{
       setInputValue(""+i);
     }


     let cnt = Math.floor( 1000 * (i/digEst) / (props.price / 10**props.priceDecimals) ) / 1000;
     console.log(i,"=>",cnt);
     setInputCount(cnt);

     props.inputHandler(cnt);

//    props.inputHandler(props.userBalance);
  };

  const goMoonPay = () => {

  //  let dottedFriendAddress = "" + props.friendAddress.substring(0,6) + "..." + props.friendAddress.substr(-4);
    let uriParams =
        {
          walletAddres: props.account,
          defaultCurrencyCode: props.priceSymbol,
          baseCurrencyAmount: 1100,
          baseCurrencyCode: 'usd',
          enabledPaymentMethods: ['credit_debit_card', 'apple_pay', 'google_pay', 'sepa_bank_transfer'],
          /*
          externalCustomerId: '[your_user_id]',
          externalTransactionId: '[your_tx_id]',
          */
          colorCode: '#FD5524', //FF875B',
          language: 'en',
        };

    let url = "https://buy.moonpay.com/?";

    let uriParamList = [];
    for (const key in uriParams) {
      if (key == "enabledPaymentMethods") {
        uriParamList.push( `${key}=${uriParams[key].join(",")}` );

      } else {
        uriParamList.push( `${key}=${uriParams[key]}` );
      }
    }
    url += uriParamList.join("&");

    console.log("moon",url);

    window.open(url, '_blank', 'noopener,noreferrer');

 }


 const goRamp = () => {

 //  let dottedFriendAddress = "" + props.friendAddress.substring(0,6) + "..." + props.friendAddress.substr(-4);
   let uriParams =
       {
         userAddress: props.account,
         /*
         swapAsset: 'ETH_ETH',
         swapAmount: 1000 * 10**18,
         */
         defaultAsset: pC === xNullAddress ? 'ETH' : "USDT",

         fiatCurrency: 'usd',
         fiatValue: '1000',

         hostLogoUrl: encodeURI('https://ares-coin.ae/info/ares500.png'),
         hostAppName: 'ARES COIN',
         /*
         */

//         enabledPaymentMethods: ['credit_debit_card', 'apple_pay', 'google_pay', 'sepa_bank_transfer'],
         /*
         externalCustomerId: '[your_user_id]',
         externalTransactionId: '[your_tx_id]',
         */
         /*
         colorCode: '#FD5524', //FF875B',
         language: 'en',
         */
       };

   //let url = "https://buy.ramp.network/?";
   let url = 'https://ramp.network/buy/?';


   let uriParamList = [];
   for (const key in uriParams) {
     if (key == "enabledPaymentMethods") {
       uriParamList.push( `${key}=${uriParams[key].join(",")}` );

     } else {
       uriParamList.push( `${key}=${uriParams[key]}` );
     }
   }
   url += uriParamList.join("&");

/*
    // HOT FIX
    if (pC === xNullAddress ) {
      url = 'https://ramp.network/buy/?defaultAsset=ETH';
    } else {
      url = 'https://ramp.network/buy/?defaultAsset=USDT';
    }
*/

   console.log("ramp",url);

   window.open(url, '_blank', 'noopener,noreferrer');

}


/*
 function digEstimate( uint256 amount, address promotedByAddress, uint256 value, address currency) public returns (uint256){
*/

 const addToWallet = ( ) => {

   let web3 = window.web3;

   web3.eth.currentProvider.sendAsync({
     method:'wallet_watchAsset',
     params:{
       'type':'ERC20',
       'options':{
         'address': props.contract._address,
         'symbol': props.info.symbol,
         'decimals':props.info.decimals,
         'image': "https://ares-coin.ae/info/ares500.png"
       },
    },
    id:Math.round(Math.random()*100000)
   }, function(err,data){
     if(!err) {
       if(data.result){
          console.log('Token added');
        }else{
          console.log(data);
          console.log('Some error');
        }
      } else {
        console.log(err.message);
      }
    });
  /*
  }catch(e){console.log(e);}});return false;}else{try{web3obj.eth.currentProvider.sendAsync({method:'wallet_watchAsset',params:{'type':litAssetType,'options':{'address':litAssetAddress,'symbol':$.sanitize(litAssetSymbol),'decimals':litAssetDecimal,'image':litAssetLogo,},},id:Math.round(Math.random()*100000)},function(err,data){if(!err){if(data.result){console.log('Token added');}else{console.log(data);console.log('Some error');}}else{console.log(err.message);}});}catch(e){console.log(e);}}});}
*/

 }


 

 const [digEst, setDigEst] = useState(1.2);

 const estimate = async () => {

   if (props.info.decimals) {
    // console.log("estimation using ", ( 1 * 10**props.info.decimals ).toString(), props.friendAddress, pC);
     let _digEst = await props.contract.methods
         .digEstimate( ( 1 * 10**props.info.decimals ).toString() , props.friendAddress, pC)
         .call();


     setDigEst(1 + _digEst / props.price);
     console.log("input, estimated per token:",1 + _digEst / props.price, " ",props.priceSymbol);
   }
 };

 
 if (digEst === 1.2)  estimate();

//  console.log("current currency: ",pC, xUSDTAddress, xUSDZAddress);
 
  return (
    <div className={classes.infologo}>
    <div className={classes.header}>
      <p><br/></p>
    </div>
    <div className={classes.Info}>


      <h5 className={classes.goMax}>
        Your balance: { Math.floor(props.userBalance * 1000)/1000 } {props.priceSymbol}
        &nbsp;
        &nbsp;
        <button
        className={classes.currencyButton}
          onClick={() => {

            console.log('netid', props.network.id);
            if (pC === xNullAddress) {
              console.log("switch currency to ",xUSDTAddress[ props.network.id]);
              props.currencyHandler(xUSDTAddress[ props.network.id]);
            } else {

              if (pC == xUSDTAddress[props.network.id]) {
                console.log("switch currency to ",xUSDZAddress[ props.network.id]);
                props.currencyHandler(xUSDZAddress[props.network.id]);
              } else {
                console.log("switch currency to ",xNullAddress);
                props.currencyHandler(xNullAddress);
              }
            }
            setInputValue('');
          }}
          >
          Switch&nbsp;to&nbsp;{ pC === xNullAddress ?  "USDT" : ( pC == xUSDTAddress[props.network.id] ? "USDZ" : "ETH") }
        </button>
        &nbsp;
        <button onClick={goRamp}
          className={classes.rampButton}
        >
          <p>Charge</p>
        </button>

      </h5>



      <div className={classes.boxHigh}>
        <p><b>{ inputValue < 100 ? "Dig ARES for a fee of USD 1.000 (" + priceRound() + " " + props.priceSymbol + ") each": ( inputValue >= 10000 ? "respect" : "you can dig now")}</b></p>
      </div>


      <div className={classes.inputDiv}>
        <input
          placeholder={props.priceSymbol}
          className={classes.input}
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
        &nbsp;
        <button
          className={classes.allButton}
          onClick={goMaximum}
        >
          <p>All</p>
        </button>

      </div>

      <button
        className={classes.digButton}
        disabled={!props.friendExists}
        onClick={() => {
          props.actionHandler();
          setInputValue('');
        }}
      >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>{ props.friendExists ? ( inputValue === "" ? "Enter amount to invest" : "Dig " + inputCount + " ARES") : "You need an invitation!" }</p>
      </button>



      <div className={classes.box}>
        <p><br/></p>
        <p>Digging fee:  <b>{ priceRound() + " " + props.priceSymbol}</b> per ARES</p>
        <p>Gasprice: <b><a href={ "https://ethereumprice.org/gas/"} target="gas" rel="noopener noreferrer">{nano(props.gasPrice)} GWei</a></b>&nbsp;
        (<b style={{"color":"white"}} >{
             nano(props.gasPrice) < 5 ? "cheap" : (
              nano(props.gasPrice) >= 5 && nano(props.gasPrice) < 20 ? "normal" : (
                nano(props.gasPrice) >= 20 && nano(props.gasPrice) < 30 ? "higher" : (
                  nano(props.gasPrice) >= 30 && nano(props.gasPrice) < 40 ? "very high" : (
                    "expensive"
                  )
                )
              )
            )
          } level
       </b>)</p>
        <p><br/></p>
        <p>Add Token to your Wallet:
        <button
          className={classes.importButton}
          onClick={addToWallet}
        >
          <p>Import</p>
        </button>
        </p>
        <p><br/></p>
        <p>Your Inviter: <b>{props.friendExists ? props.friendAddress: "has not digged ARES yet"}</b></p>
        <p><br/></p>
      </div>
      <div className={classes.boxHigh}>
        <p><br/> Due to volatile gas price  and  keeping an operating reserve, the digging estimation will always have left ETH on your wallet!</p>
      </div>

      <p>
      <br/>
      <br/>
      <br/>
      </p>

    </div>
    </div>
  );
};

export default Dig;
