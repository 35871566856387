
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

import { sha256 } from 'js-sha256';

// abis now
import ERC20 from '../src/abis/ERC20.json';
//import LEO from '../src/abis/LEO.json';


import ROCK2 from '../src/abis/ROCK2.json';
import ROCKSHIELD from '../src/abis/ROCKSHIELD.json';

import ARES from '../src/abis/ARES.json' ;
import ARESTOOLS from '../src/abis/ARESTOOLS.json' ;
import ARESSHIELD from '../src/abis/ARESSHIELD.json' ;


// styles
import classes from './App.module.css';


// components
import Navigation from './components/Navigation';

import Balance from './components/Balance';
import Block  from './components/Block';
//import UnBlock from './components/UnBlock';
import Dig  from './components/Dig';
import Pay  from './components/Pay';
import Info from './components/Info';
import Tx from './components/Tx';

import Origin from './components/Origin';
import Options from './components/Options';

import { xNullAddress, xOpCode, xBalance } from './X';

//import AdminTesting from './components/AdminTesting';

import icon from './assets/icon.png';
import icon2 from './assets/icon2.png';
import aresIcon from './assets/ares500.png';


//const ROCK = ROCK2;



const uriParts = window.location.href.split("/");

window.rckCurrency = xNullAddress;

window.dMode = 0;

const App = ( props ) => {

//  console.log("app props",props);

//  const chainId = 4;
  //const [rckCurrency, setrckCurrency] = useState( xNullAddress );


  const chainId = 1;

  const contractMap ={
    1: { shield: ARESSHIELD, library: ARES, ext: ARESTOOLS },
    4: { shield: ROCKSHIELD, library: ROCK2, ext: ARESTOOLS },
    5: { shield: ARESSHIELD, library: ARES, ext: ARESTOOLS },
  };

  const [account, setAccount] = useState('Connecting');
  const [network, setNetwork] = useState({ id: '0', name: 'none' });
  const [gasPrice, setGasPrice] = useState( 40 * 10**(18-9));

  const [appStatus, setAppStatus] = useState(true);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);

  const [rckTokenContract, setrckTokenContract] = useState('');
  const [rckTokenExt, setrckTokenExt] = useState('');
//  const [rckStatus, setrckStatus] = useState({ flags: [], dates: []});
//  const [rckFees, setrckFees] = useState({  denominator: '0',  royalties: [{ account: '0x0', value: '0'}],  market: { account: '0x0', value: '0' }  });

  const [rckAccess, setrckAccess] = useState('');

  const [rckPrice, setrckPrice] = useState('');
  const [rckPriceDecimals, setrckPriceDecimals] = useState('');
  const [rckPriceCurrency, setrckPriceCurrency] = useState(xNullAddress);
  const [rckPriceSymbol, setrckPriceSymbol] = useState('');

  const [rckRockPrice, setrckRockPrice] = useState('');
  const [rckRockPriceDecimals, setrckRockPriceDecimals] = useState('');
  const [rckRockPriceCurrency, setrckRockPriceCurrency] = useState(xNullAddress);
  const [rckRockPriceSymbol, setrckRockPriceSymbol] = useState('');


  const [rckDigged, setrckDigged] = useState(false);
  const [rckRockFee, setrckRockFee] = useState(0);
  const [rckRockFeeUnit, setrckRockFeeUnit] = useState(0);

  const [rckFriendExists, setrckFriendExists] = useState(false);
  const [rckFriendReal, setrckFriendReal] = useState(false);
  const [rckFriendAddress, setrckFriendAddress] = useState('0x0');

  const [rckContractInfo, setrckContractInfo] = useState({ totals: [0,0,0,0,0,0] });
  const [rckContractBalances, setrckContractBalances] = useState([]);

  const [rckPayoutValue, setrckPayoutValue] = useState(0);
  const [rckPayoutRealized, setrckPayoutRealized] = useState(0);

  const [inputBlock, setInputBlock] = useState('');
  const [inputUnBlock, setInputUnBlock] = useState('');
  const [inputDig, setInputDig] = useState('');

  const [inputFactor, setInputFactor] = useState('');

  const [inputTxBlock, setInputTxBlock] = useState('');
  const [inputTxAddress, setInputTxAddress] = useState('');
  const [inputTxCurrency, setInputTxCurrency] = useState('');
  const [inputTxCurrencySymbol, setInputTxCurrencySymbol] = useState('');
  const [inputTxCurrencyBalance, setInputTxCurrencyBalance] = useState(0);
  const [inputTxCurrencyDecimals, setInputTxCurrencyDecimals] = useState(18);
  const [inputTxOrder, setInputTxOrder] = useState('');
  const [inputTxCode, setInputTxCode] = useState('');


  const [uriInvite, setUriInvite] = useState('');

  const [tickerEnabled, setTicketEnabled] = useState(false);

  const [rckSubs, setrckSubs] = useState({ childs: [], balances: [] });

  const [chainChangeCounter, setChainChangeCounter] = useState(0);

  const [userBalance, setUserBalance] = useState('0');




//  const fee = ( 0.001 * 10**18).toString();



  useEffect(() => {
    //connecting to ethereum blockchain
    const ethEnabled = async () => {
      fetchDataFromBlockchain();
    };

    ethEnabled();
  }, []);



  if (window.ethereum && !window.changesRegisterd) {

    window.ethereum.on('accountsChanged', function (accounts) {
      // Time to reload your interface with accounts[0]!
      console.log(accounts);

      fetchDataFromBlockchain();
    });


    window.ethereum.on('networkChanged', function (networkId) {
      // Time to reload your interface with the new networkId
      console.log(networkId);

      fetchDataFromBlockchain();
    });

    window.changesRegisterd = 1;
  }


  let livePebblesCounter = 0;

  const setLivePebbles = ( on ) => {
    if (on) {
      livePebblesCounter++;
      window.livePebbles=livePebblesCounter;
    }else {
      window.livePebbles=0;
    }
    return window.livePebbles;
  }



//  const getRandomStr = () => Math.random().toString(36).slice(2);


  const crpt = (sash, d) => {

      var m = [ [3,30], [5,9], [11,20] , [8,12]  ];
      var hash = sash.split('');

      if (hash[1]==='x') {
//        console.log("##c#");
        for (var i=0; i<m.length; i++) {
          let t = "";
          if (d) {
            t = hash[m[i][0]];
            hash[m[i][0]] = hash[m[i][1]];
            hash[m[i][1]] = t;
          }
          else
          {
            t = hash[m[i][1]];
            hash[m[i][1]] = hash[m[i][0]];
            hash[m[i][0]] = t;
          }
        }
      }
      hash[1]="x";
      return hash.join('');
  }




//uriParts[2] + (uriParts[-1]==='?' ? "" : '?')


/*
  const validAddress = (ethereumAddress) => {
      let regex = /^0x[0-9a-fA-F]{40}$/;
      if ( ethereumAddress.match(regex)) {
          console.log(ethereumAddress,"ok");

          return true;
      }
      console.log(ethereumAddress,"failed");
      return false;
  }
*/

const fetchSubsFromBlockchain = async ( props ) => {

  if (!appStatus) {
  } else {

    console.log("tree");

    //setLoader(true);

    if ( window.ethereum ) {

      let web3 = window.web3;

      const accounts = await web3.eth.getAccounts();
//      const networkId = await web3.eth.net.getId();

      //await MyToken.at(impl_proxy.address)

      let rckToken = props.token;

      let e = await rckToken.methods
          .isMember(accounts[0])
          .call();

      if (!e) return;


      let ch = await rckToken.methods
          .getMemberChilds(accounts[0])
          .call();

      let b = [];

      ch.forEach( async (a) => {

        let mb = await rckToken.methods
            .balancesOf(a)
            .call();

        b.push(mb);
      });

      console.log({  childs: ch, balances: b});
      setrckSubs({ childs: ch, balances: b});
    }
    //setLoader(false);
  }
}



  const fetchTickerInterpolate = async ( props ) => {


    if (!window.livePebbles) {
      console.log("ticker pebbles disabled, finished.");
      return;
    }

    if (window.livePebbles !== props.nb ){
      console.log("ticker pebbles ",props.nb," stopped, newer pebbles started ",window.livePebbles);
      return;
    }

    if (props.tick / props.frame < 1){
      console.log("ticker pebbles stopped, no progress, finished ",props);
      setTicketEnabled(false);
      return;
    }

//    console.log("ticker pebble", props);

//    fetchTickerInterpolate( { counter: t*4, balances: balance, tick: tick, fps: 4 , value: balance, last: props.last} );

    let p = {
      nb: props.nb,
      idx: props.idx + 1,
      count: props.count,
      balances: props.balances,
      ticks: props.ticks,  // delta per tick -> not per frame!
      last: props.last,
//      value: value,
      fps: props.fps
    }

    let tickNonZero = false;
    let b = [];
    let i = 0;
    while (i < props.balances.length) {
      if ( props.ticks[i] ) {
        b[i] = parseInt(props.balances[i]) + Math.round( props.idx * props.ticks[i] /  props.fps);
//        console.log("diff balance", i, b[i]);

        tickNonZero = true;
      } else {
        b[i] = props.balances[i];
      }
      i++;
    }
    setrckContractBalances(b);


    if (!tickNonZero){
      console.log("ticks are zerod, finished.");
      setTicketEnabled(false);
      return;
    }
//    console.log("set", b);

    const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
    await waitFor( Math.round( 1000 / props.fps));

    if (p.idx < p.count) {
      fetchTickerInterpolate( p );
    } else {
      console.log("ticker pebbles finished.");
      setTicketEnabled(false);
    }
  }




  const getGasPrice = async ( props ) => {

    window.web3 = new Web3(window.ethereum);

    const overhead = "2";
/*
    if (props.overhead ) {
      overhead = props.overhead;
    }
    */
    const gasPriceOverhead = 1.1;
    const gasPriceTip = 2  * 10**9;
    const gasPrice_ = await window.web3.eth.getGasPrice();
    const networkId = await window.web3.eth.net.getId();

    if (networkId === 1 || networkId === 5) {
      let gasIdea = (   Math.round(parseInt(gasPrice_)  * gasPriceOverhead) + gasPriceTip ).toString();
      console.log("gasprice:",gasIdea,gasPrice_, gasPriceOverhead, window.web3.utils.toWei('40','gwei'));
      setGasPrice(gasPrice_);
      return gasIdea;
    }

    return window.web3.utils.toWei('40', 'gwei');
 }

  const fetchTickerFromBlockchain = async ( props ) => {

    if (!appStatus) {
    } else {

//      let b=[];

      console.log("ticker pebbles fetch #### ",props);


      // let finsish the job
      setTicketEnabled(false);

      if ( window.web3 ) {

        let web3 = window.web3;

        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();
        const gasPrice_ = await web3.eth.getGasPrice();


        // LIVEFIX
        if (networkId === 1 || networkId === 5) {
          setGasPrice(gasPrice_);
        }

        let rckToken = props.token;

        console.log("rockBalances check", accounts[0]);
        let balance = await rckToken.methods
            .balancesRockOf(accounts[0])
            .call();

        if (balance) {
          console.log("rockBalances got",balance);
          setrckContractBalances(balance);
        } else {
          console.log("rockBalances failed");
        }


        let t = 5;
        if (networkId === 4) {
          t = 15;
        }
        if (! props.first) {
          t = 60;  // will be overwritten if  there is no tick delta => 2*lastTick
        }

        let tickZero = true;

        if (!props.first && props.last.length > 2 && balance) {

           let tick = [];
           let i =0;
           for (i>xBalance.blocked; i<xBalance.rn; i++) {
             if (balance[i] > 0) {
               tick[i] = Math.round( (balance[i] - props.last[i]) / props.lastTick );
               if (tick[i]) {
                 tickZero=false;
               }
             }
           }

           if (tickZero) {
             console.log("all ticks are zero:", tick);
           }
           console.log("diff:", (balance[5] - props.last[5]), props.lastTick , tick);

           let nb = setLivePebbles(true);
           console.log("ticket pebbles nb ",nb," started.");

           fetchTickerInterpolate( { nb: nb, idx: 0, count: t*4, balances: balance, ticks: tick, fps: 4 , last: props.last} );
        }


        if (!props.first && tickZero) {
          console.log("wait further ",props.lastTick);
          const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
          await waitFor(props.lastTick * 1000);
          console.log("call fetch ticker  zero");
          fetchTickerFromBlockchain( { token: rckToken, last: props.last, first: false , lastTick: props.lastTick * 2} );
        } else {
          console.log("wait for ",t);
          const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
          await waitFor(t * 1000);
          fetchTickerFromBlockchain( { token: rckToken, last: balance, first: false , lastTick: t } );
          console.log("call fetch ticker  regular");
        }
      }
   }

  }


  const changeNetwork = async ( props ) => {

   console.log("change of network requested cnt:",chainChangeCounter);

   setChainChangeCounter( chainChangeCounter + 1 );

   if (window.ethereum) {

     window.web3 = new Web3(window.ethereum);

     try {
       await window.ethereum.request({
       method: 'wallet_switchEthereumChain',
         params: [{ chainId: window.web3.utils.toHex(props.chainId) }],
       });

       //window.location.reload();


       fetchDataFromBlockchain();
/*
       if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(  navigator.userAgent  ))
        fetchDataFromBlockchain();
       else
        window.location.reload();
*/
     } catch (error) {
       console.error(error);
     }
   }
 }





 const f0 = async ( rckToken, rckTokenExt, accounts ) => {

   let web3 = window.web3;

   console.log("digged");
   let digged = await rckToken.methods
     .hasDig(accounts[0])
     .call();
   setrckDigged(digged);


   console.log("prom",accounts[0]);
   let promAddress = await rckToken.methods
     .getPromotedBy(accounts[0])
     .call();

   //console.log("friendcheck: got promAddress",promAddress,"account",accounts[0]);



   let friendExists = false;
   let friendReal = false;
   let friendAddress = promAddress;

   if (promAddress !== xNullAddress) {
     console.log("friendcheck: prom", promAddress);
//     friendAddress = promAddress;
     friendReal = true;
     friendExists = true;
   } else {

     let up = window.location.href.split("/");
     console.log("friendcheck: no prom, uri:",up);
     if (up.length > 1) {

       let _friendAddress = crpt( up.pop() ,false);
       if ( web3.utils.isAddress(_friendAddress)) {
         friendReal = true;
         //console.log("friendcheck: Address is real: ",_friendAddress);
         friendExists = await rckToken.methods
           .isMember(_friendAddress)
           .call();
         if (friendExists) {
           //console.log("friendcheck: exists");
           friendAddress = _friendAddress;
         } else {
           console.log("friendcheck: not exists");
         }
       } else {
         console.log("friendcheck: Address not real");
       }
     } else {
       console.log("friendcheck: invitation's missing");
     }
   }


   setrckFriendReal(friendReal);
   setrckFriendExists(friendExists);
   setrckFriendAddress(friendAddress);

   //console.log("friendcheck: summary:",friendExists, friendReal, friendAddress);



   // build invite link
   const up = window.location.href.split("/");
   if (up.length > 0) {
     setUriInvite( [ up[0] ,  up[1] ,  up[2] + (up[2][-1]==='?' ? "" : '?') , crpt(accounts[0],true) ].join("/") );
   } else {
     console.log("nonconform uri: ''",window.location.href,"'");
   }

    let payout = await rckTokenExt.methods
    .payOutValue(accounts[0])
    .call();

    setrckPayoutValue(payout);


    let payout_ = await rckTokenExt.methods
    .getPayout(accounts[0])
    .call();

    setrckPayoutRealized(payout_);


   console.log("parallel end f0");
 }


 const f1 = async ( rckToken, rckTokenExt, accounts ) => {
   //  fetching balance of Testtoken and storing in state
   console.log("contract infos");
   let contractInfo = {
       totals: await rckTokenExt.methods.totals2().call(),
       decimals: await rckToken.methods.decimals().call(),
       feeDenominator: 1000, //await rckToken.methods.feeDenominator().call(),
       symbol: await rckToken.methods.symbol().call(),
       name: await rckToken.methods.name().call(),
       totalFlowEther:    await rckToken.methods.totalFlow(xNullAddress).call(),
//              totalFlowUSDT:    await rckToken.methods.totalFlow(USDT.address).call(),
       blockTime:    await rckToken.methods.getTimeStamp().call(),

//              lastBlocking: await rckToken.methods.lastBlockingOf(accounts[0]).call(),
       isProtected: await rckToken.methods.isProtected(accounts[0]).call(),

       apy: await rckToken.methods.getAPY( Date.now() ).call(),
       ver: await rckToken.methods.version().call(),
       limit: await rckToken.methods.digLimit().call()
   }


   setrckContractInfo(contractInfo);
   console.log("infos:",contractInfo);

   console.log("rock fee");

   let rockFee = await rckToken.methods
     .getFee()
     .call();
   setrckRockFee(rockFee.fee);
   setrckRockFeeUnit(rockFee.unit);
   console.log("parallel end f1");


 }


 const f2 = async ( rckToken, accounts ) => {

   let balance = await rckToken.methods
       .balancesOf(accounts[0])
       .call();

   if (balance) {
     console.log("balances",balance);
     setrckContractBalances(balance);
   } else {
     console.log("balances failed");
   }

   console.log("parallel end f2");
 }


 const f3 = async ( rckToken, rckTokenExt, accounts, currency ) => {

   let web3 = window.web3;

   //  fetching balance of Testtoken and storing in state
   let price;

   price = await rckTokenExt.methods.getPrice(currency).call();
   setrckPrice(price.price);
   setrckPriceCurrency(price.currency);
   setrckPriceDecimals(price.decimals);



 //          setrckPrice(price.forSale);
   console.log("digprice",price);

   if (price.currency === xNullAddress) {
     const result = await web3.eth.getBalance(accounts[0]);

     setrckPriceSymbol( "ETH" );

     console.log("balance:",web3.utils.fromWei(result, "ether") + " ETH");
     setUserBalance(web3.utils.fromWei(result,"ether"));
   } else {

     let erc = await erc20Helper(price.currency, accounts[0]);

     setrckPriceSymbol( erc.s);

     console.log("balance:",  parseInt(erc.b)/ 10**price.decimals,  erc.s, erc);
     setUserBalance( parseInt(erc.b) / 10**price.decimals );
   }



   //  fetching balance of Testtoken and storing in state
   let rockPrice = await rckToken.methods
     .getRock()
     .call();

   setrckRockPrice(rockPrice.price);
   setrckRockPriceCurrency(rockPrice.currency);
   setrckRockPriceDecimals(rockPrice.decimal);
   //          setrckPrice(price.forSale);
   console.log("rockprice",rockPrice);

   if (rockPrice.currency === xNullAddress) {
     setrckRockPriceSymbol( "ETH" );
   } else {
     let erc = await erc20Helper(rockPrice.currency, accounts[0]);
     setrckRockPriceSymbol( erc.s);
   }


   console.log("parallel end f3");
  }


  const fetchDataFromBlockchain = async () => {


    if ( window.ethereum ) {

/*
      console.log("chain",window.ethereum.networkVersion, chainId);
      if (window.ethereum.networkVersion != chainId) {
        changeNetwork({ chainId: chainId });

        return;
      }
*/

      console.log("fetch data....");

      await window.ethereum.request({ method: 'eth_requestAccounts' });


      //connecting to metamask
      window.web3 = new Web3(window.ethereum);
      let web3 = window.web3;

      console.log(web3);



      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);


      //loading users network ID and name
      const networkId = await web3.eth.net.getId();
      const networkType = await web3.eth.net.getNetworkType();
      setNetwork({ ...network, id: networkId, name: networkType });

      console.log("x2");


      const CONTRACT = contractMap[networkId];
      const rckTokenData = CONTRACT.shield.networks[networkId];
      if (rckTokenData) {

        let web3 = window.web3;

        const rckToken = new web3.eth.Contract(
          CONTRACT.library.abi,
          rckTokenData.address
        );

        const rckTokenExt = new web3.eth.Contract(
          CONTRACT.ext.abi,
          rckTokenData.address
        );


        setrckTokenContract(rckToken);
        setrckTokenExt(rckTokenExt);

        window.shield = rckTokenData;
        window.token = rckToken;
        window.account = accounts[0];
        window.sha256 = sha256;


         console.log("parallel start");
          // load parallelized
          f3(rckToken, rckTokenExt, accounts, window.rckCurrency);
          f0(rckToken, rckTokenExt, accounts);
          f1(rckToken, rckTokenExt, accounts);
          f2(rckToken, accounts);


          setLivePebbles(false);

          //if (!tickerEnabled)
          console.log("call fetch ticker  init");
          fetchTickerFromBlockchain({ token: rckToken, last: [], first: true });

          fetchSubsFromBlockchain({ token: rckToken });

      }

      //removing loader
      setLoader(false);
    } else if (!window.web3) {

      console.log("no ethereum available");
      setAppStatus(false);

      setAccount('Metamask is not detected');
      setLoader(false);
    } else {

      setAccount('Miau');
      setLoader(false);
    }
  };


  const inputFactorHandler = (received) => {
    setInputFactor(received);
  };




  const inputBlockHandler = (received) => {
    console.log("block:",received);
    setInputBlock(received);
  };

  const inputUnBlockHandler = (received) => {
    setInputUnBlock(received);
  };

  const inputTxBlockHandler = (received) => {
    setInputTxBlock(received);
  };

  const inputTxAddressHandler = (received) => {
    setInputTxAddress(received);

    setInputTxCode(0x00);
    setInputTxOrder('');
  };

  const inputTxCurrencyHandler = (received) => {
    setInputTxCurrency(received);

    setInputTxCode(0x00);
    setInputTxOrder('');
  };

  const inputTxOrderHandler = (code, target, currency, amount, order ) => {

//    const rckDo = async ( op_, v_, t_) => {
    console.log("got order",code,target,currency,amount,order);

    setInputTxCode(code);
    setInputTxAddress(target);
    setInputTxCurrency(currency);
    setInputTxBlock(amount);
    setInputTxOrder(order);

    // ethereum

    rckCurrencyInfo( currency, amount ) ;

    // only for non-rock and non-eth
    // if (received != txCurrency && received != rckTokenContract.address) {
    //  rckApproval( received );
    // }
  };


  const erc20Helper = async ( currency, account ) => {

        let web3 = window.web3;

        const coinToken = new web3.eth.Contract(
          ERC20.abi,
          currency
        );

        let s = await coinToken.methods
          .symbol()
          .call();

        let b = await coinToken.methods
          .balanceOf(account)
          .call();

        let d = await coinToken.methods
          .decimals()
          .call();

        return {
          s: s,
          b: b,
          d: d,
          t: coinToken
        };
  }


  const erc20AA = async ( currency, account, operator, amount ) => {

        let web3 = window.web3;

        const coinToken = new web3.eth.Contract(
          ERC20.abi,
          currency
        );


        console.log("check currency allowance for ", account,"/",operator );
        let a = await coinToken.methods
          .allowance(account,operator)
          .call();

        console.log("currency allowance for ", account,"/",operator, " is ", a, "should ",amount);

        if (a < amount) {
          console.log("allowance too low, approve for more...");
           await rckApproval( currency, amount.toString() );
        } else {
          console.log("allowance enough, do not approve for more...");
        }
  }



  const rckCurrencyInfo = async ( txCurrency, txAmount ) => {

//    setLoader(true);

    let web3 = window.web3;

    if (txCurrency === '0x0') {
      txCurrency=xNullAddress;
    }

    // Ethereum fallback
    if (txCurrency === xNullAddress) {
      setInputTxCurrencySymbol( "ETH" );
      setInputTxCurrencyDecimals( 18  );
      setInputTxCurrencyBalance( await web3.eth.getBalance(account));
      return;
    }


    let erc = await erc20Helper(txCurrency, account);

    console.log("currency symbol:", erc.s);
    setInputTxCurrencySymbol( erc.s );
    console.log("currency balance:", erc.b);
    setInputTxCurrencyBalance( erc.b );
    console.log("currency decimals:", erc.d);
    setInputTxCurrencyDecimals( erc.d );

    //let ad = window.token._address; //rckTokenData.address;

    await erc20AA( txCurrency, account, rckTokenContract.options.address, txAmount );

 }


  const rckApproval = async ( txCurrency, txAmount ) => {

//    setLoader(true);

    let web3 = window.web3;
    let ad = window.token._address; //rckTokenData.address;

    if (ad === txCurrency) {
        console.log("no need to approve for this contract.");
        return;
    }

    const coinToken = new web3.eth.Contract(
      ERC20.abi,
      txCurrency
    );


    //FIX ME: approve as ordered
    txAmount =  web3.utils.toWei("1000000");

   await coinToken.methods
    .approve( ad, txAmount  )
    .send({
      from: account,
      gasLimit: web3.utils.toHex(220000)
//      ,
//      gasPrice: web3.utils.toHex(await getGasPrice())
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      console.log("approval confirmed");

    })
    .on('transactionHash', (hash) => {
      console.log("approval tx");
    })
    .on('receipt', (receipt) => {
      console.log("approval receipt");
    })
    .on('error', function(error) {
      console.log('Error Code:', error.code);
      console.log(error.code);
  //    setLoader(false);
    });

  }








  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  }

  const rckUnBlock = async ( ) => {
      await rckDo( xOpCode.unBlock , inputUnBlock, xNullAddress, null ,null);
  }
  const rckBlock = async () => {
    await rckDo( xOpCode.block , inputBlock, xNullAddress, null ,null);
  }

  const rckTxBlock = async () => {
    if ( inputTxOrder==="" ) {
      await rckDo( xOpCode.txBlock , inputTxBlock, inputTxAddress, null ,null);
    } else {
      await rckDo( xOpCode.txPay , inputTxBlock, inputTxAddress, inputTxCurrency ,inputTxOrder);
    }
  }

  const rckProtect = async () => {
    await rckDo( xOpCode.protect , getRandomInt(932103213) , xNullAddress, null ,null);
  }

  const rckCheck = async () => {
    await rckDo( xOpCode.check , getRandomInt(932551113) , xNullAddress, null ,null);
  }


  const rckDo = async ( op_, v_, t_, c_, o_) => {
    if (!appStatus) {
    } else {

      if (!v_ || v_ <= 0) { return; }


      if ( window.web3.utils.isAddress(t_)) {
        /*
        let exists = await rckTokenContract.methods
          .isMember(t_)
          .call();
          if (!exists) {
            console.log("address not exists");
            return;
          }
          */
      } else {
        console.log("address not valid");
      }

      let rFee = rckRockFee;
      if (op_ === xOpCode.block || op_ === xOpCode.unBlock || op_ === xOpCode.txBlock ) {
         rFee =  Math.floor(rckRockFee * v_ / ( rckRockFeeUnit * 10**rckContractInfo.decimals));
      }

      if (op_ === xOpCode.txPay ) {
        op_ = 0x123;
      }

//      rFee = (rFee).toString();

      //v_ = parseInt(v_);
/*
      let op_ = 0x111;
      let v_  = 1000;
      let t_  = 0;

      let rckTokenContract = window.token;
      let account = window.account;
*/
      console.log("xc:got  args", op_, t_, v_, c_, o_, rFee);





//--------------------------------------

/*

  op_ = 0x300 | 0x11;  // base | mode
  t_  = "0xA0a9Cb43FCe79d33e7F3B2D4840963EC7893d40a"; //  0x54A3615Fac9AC5b03e28E9375Fe0079fbF4232C7";
  c_  = "0x5F3151Dd5a90bf91AfF676f91cc2ADb6E0dB4258"; // USDT
  v_  = 100310000; //100.31 * 10**10;
  o_  = "AJ112ZZ_e3e213__";


  xNullAddress =  '0x0000000000000000000000000000000000000000';

  const rckTokenContract = new web3.eth.Contract(
    window.leoabi,
    "0x74503e1dD5E3668ccc9C0f2EAd0E71Fc6A7d1098"
    //"0x5808653469Eedc5c0F2e5e6C096bD0438FEe8372"
    //"0xf15038535943f3c04D54b4bBc446f1F64A635233"
    //"0xab37da98d99D378F0bF0484eE421790C3B7618aB"
    //"0x40F5fd3Fcb17Aa0Fdf253042B713b5e022B2E1A9"
    //"0x2C489D4d9BD9C7dE1A57A0535410C2B76c27FB5c"
    //"0x10Ce64E688b658c660bF71043609154474eAFb06"
    //"0x134998a22B4F50A08C8f0a61f38Be7AED6D80570"
    //"0x1Ec16D928F145666601D2adA465Cae9722812371"
    //"0xB62Cb6623a8b80cDDEc61D27b638F2caaEdcd2cE"
  );

  */

      let u = window.web3.utils;

      function rm0x(r) {
        return r.substring(2);
      }



      const b = new Uint32Array(8);
      crypto.getRandomValues(b);
      let k = window.web3.utils.toHex(btoa(b[0]*b[1]*b[2]*b[3]*b[4]*b[5]*b[6]*b[7]))

      let isMize = await rckTokenContract.methods
        .isMize(window.account)
        .call();


        if (!isMize) { //} == '0') {
          setLoader(true);

          //let result =
          await rckTokenContract.methods
            .doMize(window.account)
            .send({
              from: window.account,
              gasLimit: window.web3.utils.toHex(400000),
              gasPrice: window.web3.utils.toHex(await getGasPrice())
            })
/*
            rn_ = await rckTokenContract.methods
              .getPre(window.account)
              .call();
*/
            setLoader(false);
        }

/*
      rn_ = 0;

      //let rn = "0x" + rn.toString(16);
      let rn = u.padLeft(u.toHex(rn_),64);
      console.log(rn);

*/


      const a = new Uint32Array(12);  // FFFFFFFF   bytes8 !
      crypto.getRandomValues(a);


      if (t_ === xNullAddress || t_ === null) {
          t_  = "0x54A3615Fac9AC5b03e28E9375Fe0079fbF4232C7"; // Master of Rockiverse
      }
      if (c_ === xNullAddress || c_ === null ) {
          c_  = "0x5093098409328409328409328409832408324032"; //USDT
      }
      if (o_ === null) {
          o_ = [ a[1].toString(16) , a[2].toString(16), a[1].toString(16) , a[2].toString(16) ].join();
      }
      let maxv_ = parseInt("0x" + "FFFFF" + "FFFFF" + "FFFFF" + "FFFFF" + "FF");
      if ( v_ > maxv_ ) {
        console.log("max value capacity ",maxv_," (",v_,")reached, abort!");
//        return;
      }


      // build param one
      let fill_ = [ a[0].toString(16) , a[1].toString(16) , a[2].toString(16)  , a[4].toString(16) ];
      let fill20  = fill_.join("").substring(0,20);

      let one_ = [ u.padLeft( op_.toString(16), 4) , fill20, rm0x(t_) ];
      let one  = "0x" + one_.join("");

      console.log("xc: one: ",one_,one,one.length,   one_[2].length);


      // build param one
      let two_ = [ u.padLeft( v_.toString(16), 24)  ,  rm0x(c_) ];
      let two  = "0x" + two_.join("");

      console.log("xc: two: ",two_,two,two.length,   two_[1].length);


      // build param three
      let o__ =  sha256( o_ );

      let three_ = [  o__ ];
      let three  = "0x" + three_.join("");

      console.log("xc: three: ",three_,three,three.length,   three_[0].length, o__.length);




      console.log("xc: encode",k,[one,two,three]);

      let w = await rckTokenContract.methods.cy32( k , account).call();
      let x = await rckTokenContract.methods.cx32( one, k ).call();
      let y = await rckTokenContract.methods.cx32( two,  k ).call();
      let z = await rckTokenContract.methods.cx32( three , k ).call();

      console.log("encoded", [w,x,y,z]);

      window.p = {
        a: account,
        k: k,
        one: one,
        two: two,
        three: three
      };


      setLoader(true);
      let result = await rckTokenContract.methods
        .rock32( w,x,y,z)
        .send({
          from: account,
          value: window.web3.utils.toHex(rFee),
          gasLimit: window.web3.utils.toHex(250000),
          gasPrice: window.web3.utils.toHex(await getGasPrice())
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('receipt', (receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });

    //  console.log("rck: res:",result);
//    */
    }

  };


  const changeCurrencyHandler = (received) => {
    console.log(received);
    //setrckCurrency(received);
    window.rckCurrency = received;

    fetchDataFromBlockchain();
  };


  const inputDigHandler = (received) => {
    setInputDig(received);
  };

  const rckDig = async ( ) => {

    if (!appStatus) { return; }

    console.log({
      "inputdig": inputDig,
      "price": rckPrice,
      "dec": rckPriceDecimals,
      "cur": rckPriceCurrency,
      "inputDec": rckContractInfo.decimals
    });

    if (!inputDig || inputDig <= 0) { return; }

    let r = parseInt(parseFloat(inputDig) * 10**rckContractInfo.decimals ).toString();
    let p = parseInt(parseFloat(inputDig) * rckPrice).toString();

    console.log("estimate:",{ r, rckFriendAddress, rckPriceCurrency});
    let a = await rckTokenContract.methods
        .digEstimate( r , rckFriendAddress, rckPriceCurrency)
        .call();

    let g = (parseInt(p) + parseInt(a)).toString();


    console.log("digging of ",r);
    console.log("digging, estimated per token:",1 + a/p);

    // rckPrice is in Ethereum!
    let e = (0).toString();
    if (rckPriceCurrency == xNullAddress) {
       e = g;
    } else {
//      let ad = window.token._address; //rckTokenData.address;
      console.log(rckTokenContract);
      await erc20AA( rckPriceCurrency, account, rckTokenContract.options.address, g);
    }




    console.log("price",{
      "price": rckPrice,
      "price/10**decimals": rckPrice / (10**rckPriceDecimals),
      "e": e,
      "p": p,
      "g": g,
      "a (partof e)": a,
      "amount": inputDig,
      "r": r,
      "r/10**10": r / (10**10),
      "currency": rckPriceCurrency,
      "currency2": window.rckCurrency
    });

    let gasLimit = 1200000; //"0x1D4C0";
    if (rckDigged) {
      gasLimit  = 600000;
    }

    console.log("gp precheck:", await getGasPrice());
    console.log("gp precheck:", parseInt(await getGasPrice()) + (10*10**9));

    setLoader(true);
    rckTokenContract.methods
      .dig( r , rckFriendAddress, rckPriceCurrency )
      .send({
        from: account,
        value: window.web3.utils.toHex(e), //window.web3.utils.toWei( e, 'ether')),
        gasLimit: window.web3.utils.toHex(gasLimit),  //259,888  4x Transfer + 1x Token,   337586 for, 227,500 for 7x transfer + 1x token
        gasPrice: window.web3.utils.toHex( parseInt(await getGasPrice()) + (10 * 10**9))
      })
      .on('transactionHash', (hash) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('receipt', (receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        setLoader(false);

        fetchDataFromBlockchain();

        fetchSubsFromBlockchain({ token: rckTokenContract });
      })
      .on('error', function(error) {
        console.log('Error Code:', error.code);
        console.log(error.code);
        setLoader(false);
      });
  };

/*
  const rckCheck = async ( ) => {

    if (!appStatus) { return; }

    let rFee = (rckRockFee / 10**18).toString();

    setLoader(true);
    rckTokenContract.methods
      .recalc()
      .send({
        from: account,
//        value: window.web3.utils.toHex(window.web3.utils.toWei( rFee, 'ether')),
        gasLimit: window.web3.utils.toHex(50000),  //259,888  4x Transfer + 1x Token,   337586 for, 227,500 for 7x transfer + 1x token
        gasPrice: window.web3.utils.toHex(await getGasPrice())
      })
      .on('transactionHash', (hash) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('receipt', (receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('error', function(error) {
        console.log('Error Code:', error.code);
        console.log(error.code);
        setLoader(false);
      });
  };
*/

  const rckFactor = async ( ) => {

    if (!appStatus) { return; }

    setLoader(true);
    rckTokenContract.methods
      .digFactor( inputFactor )
      .send({
        from: account,
  //        value: window.web3.utils.toHex(window.web3.utils.toWei( fee, 'ether')),
        gasLimit: window.web3.utils.toHex(300000),  //259,888  4x Transfer + 1x Token,   337586 for, 227,500 for 7x transfer + 1x token
        gasPrice: window.web3.utils.toHex(await getGasPrice())
      })
      .on('transactionHash', (hash) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('receipt', (receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('error', function(error) {
        console.log('Error Code:', error.code);
        console.log(error.code);
        setLoader(false);
      });
  };



  const rckPayOut = async ( ) => {

    if (!appStatus) { return; }

    console.log("pay out 2");

    setLoader(true);

    rckTokenExt.methods
      .payOut()
      .send({
        from: account,
//        value: window.web3.utils.toHex(window.web3.utils.toWei( fee, 'ether')),
        gasLimit: window.web3.utils.toHex(1800000),  //259,888  4x Transfer + 1x Token,   337586 for, 227,500 for 7x transfer + 1x token
//        gasPrice: window.web3.utils.toHex(await getGasPrice())
        gasPrice: window.web3.utils.toHex( parseInt(await getGasPrice()) + (3 * 10**9))
      })
      .on('transactionHash', (hash) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('receipt', (receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('error', function(error) {
        console.log('Error Code:', error.code);
        console.log(error.code);
        setLoader(false);
      });
  };





  let metamaskURI = "https://metamask.io/download/";
  let braveURI = "https://brave.com/";
  let metamaskDeepURI = "https://metamask.app.link/dapp/" + window.location.href;



  if ( !appStatus ) {

    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(  navigator.userAgent  ))

      return (
        <div className={classes.Grid}>
          <div className={classes.Child}>
            <img src={icon} alt="logo" className={classes.icon} />
            <h1> please use mobile Metamask compatible Wallet</h1>
            <p>{account}</p>
            <p>Visit <a href={metamaskURI} target="_blank" rel="noopener noreferrer">Metamask</a> first</p>
            <p></p>
            <h1> open this Link in the Metamask's own Browser</h1>
            <p></p>
            <p>Visit <a href={metamaskDeepURI} rel="noopener noreferrer">DAPP in Metamask</a> then</p>
            <p></p>
            <p>Android Users should enable
            Apps*Standard&nbsp;Apps*Links*MetaMask*Addresses*metamask.app.link before.
            </p>
          </div>
        </div>
      )
   else
      return (
        <div className={classes.Grid}>
          <div className={classes.Child}>
            <img src={icon2} alt="logo" className={classes.icon} />
            <h1> please install Brave-Browser on Desktop or any Metamask compatible Wallet-Plugin </h1>
            <p>{account}</p>
            <p>Visit <a href={braveURI}>Brave</a> first</p>
          </div>
        </div>
      )
  }



  if ( network.id != chainId && chainChangeCounter > 10)

    return (
      <div className={classes.Grid}>
        <div className={classes.Child}>
          <img src={aresIcon} alt="logo" className={classes.icon} />
          <h1> wrong Network </h1>
          <p>{network.id}</p>
          <p></p>
          <p>Switch Network to <b>Rinkeby</b> (on Metamask Mobile via Button "...")</p>
        </div>
      </div>
    )

  if (account == 'Connecting')
    return (
      <div className={classes.Grid}>
        <div className={classes.Child}>
          <img src={aresIcon} alt="logo" className={classes.icon} />
          <h1> Connecting your Wallet </h1>
          <p>Network ID {network.id}</p>
          <p></p>
          <p>WLAN on? Internet enabled? Wallet running?</p>
          <p></p>
          <p>Refresh Dapp or Restart your Wallet</p>
        </div>
      </div>
    )


  const changePage = () => {
    if (page === 1) {
      setPage(2);
    } else if (page === 2) {
      setPage(1);
    }
  };

  if (  window.location.href.match(/[\/]options/)  ) {
    return (
      <div className={classes.Grid}>
        <div className={classes.Child}>
            <Options
              network={network}
              info={rckContractInfo}
              inputHandler={inputFactorHandler}
              actionHandler={rckFactor}
              page={page}
              balances={rckContractBalances}
              contract={rckTokenContract}
            />
        </div>
      </div>
    );
  }

  if ( /origin/i.test(  window.location.href  ))
    return (
      <Origin/>
    );

  return (
    <div className={classes.Grid}>
      {loader ? <div className={classes.curtain}></div> : null}
      <div className={classes.loader}></div>
      <div className={classes.Child}>
        <Navigation changePage={changePage} account={account}   access={rckAccess} symbol={rckPriceSymbol}>
        <div>
          <Balance
            account={account}
            network={network}
            info={rckContractInfo}
            balances={rckContractBalances}
            page={page}
            digged={rckDigged}
            Dig={rckDig}
            priceSymbol={rckPriceSymbol}
            priceDecimals={rckPriceDecimals}
            priceCurrency={rckPriceCurrency}
            rpriceSymbol={rckRockPriceSymbol}
            rpriceDecimals={rckRockPriceDecimals}
            contract={rckTokenContract}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
            friendReal={rckFriendReal}
            uriInvite={uriInvite}
            uriParts={uriParts}
            actionHandler={rckBlock}
            protectHandler={rckProtect}
          />
        </div>
        <div className={classes.Dig}>
          <Dig
            page={page}
            digged={rckDigged}
            Dig={rckDig}
            amount={inputDig}
            account={account}
            info={rckContractInfo}
            gaso={getGasPrice}
            contract={rckTokenContract}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
            price={rckPrice}
            priceSymbol={rckPriceSymbol}
            priceDecimals={rckPriceDecimals}
            priceCurrency={rckPriceCurrency}
            actionHandler={rckDig}
            inputHandler={inputDigHandler}
            currencyHandler={changeCurrencyHandler}
            userBalance={userBalance}
            gasPrice={gasPrice}
            network={network}
          />
        </div>
        <div className={classes.inStore}>
          <Block
            page={page}
            actionHandlerBlock={rckBlock}
            inputHandlerBlock={inputBlockHandler}
            actionHandlerUnBlock={rckUnBlock}
            inputHandlerUnBlock={inputUnBlockHandler}
            protectHandler={rckProtect}
            checkHandler={rckCheck}
            info={rckContractInfo}
            balances={rckContractBalances}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
          />
        </div>
        <div className={classes.inStore}>
          <Tx
            page={page}
            account={account}
            actionHandler={rckTxBlock}
            checkHandler={rckCheck}
            inputHandler={inputTxBlockHandler}
            inputAddressHandler={inputTxAddressHandler}
            inputCurrencyHandler={inputTxCurrencyHandler}
            inputOrderHandler={inputTxOrderHandler}
            currencySymbol={inputTxCurrencySymbol}
            currencyBalance={inputTxCurrencyBalance}
            currencyDecimals={inputTxCurrencyDecimals}
            currencyValue={inputTxBlock}
            contract={rckTokenContract}
            info={rckContractInfo}
            balances={rckContractBalances}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
          />
        </div>
        <div>
        </div>
        <div className={classes.pay}>
          <Pay
            page={page}
            info={rckContractInfo}
            rpriceSymbol={rckRockPriceSymbol}
            rpriceDecimals={rckRockPriceDecimals}
            balances={rckContractBalances}
            contract={rckTokenContract}
            payoutValue={rckPayoutValue}
            payoutRealized={rckPayoutRealized}
            actionHandler={rckPayOut}
            actionHandler2={rckCheck}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
          />
        </div>
        <div className={classes.info}>
          <Info
            account={account}
            network={network}
            gasPrice={gasPrice}
            info={rckContractInfo}
            balances={rckContractBalances}
            page={page}
            Dig={rckDig}
            priceSymbol={rckPriceSymbol}
            priceDecimals={rckPriceDecimals}
            contract={rckTokenContract}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
            friendReal={rckFriendReal}
            uriInvite={uriInvite}
            subs={rckSubs}
          />
        </div>
         </Navigation>
      </div>
    </div>
     );

}

export default App;
