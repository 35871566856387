import React, { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { QrReader } from 'react-qr-reader';
import base64 from 'react-native-base64';


import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';
//import mineIcon from '../assets/mine500.png';
//import rightIcon from '../assets/rightIcon.png';

import { xNullAddress , xBalance } from '../X';



const Tx = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [inputAddress, setInputAddress] = useState('');
  const [inputCurrency, setInputCurrency] = useState( xNullAddress );
  const [inputOrder, setInputOrder] = useState('');
  const [inputCode, setInputCode] = useState('');

  const [scanMode, setScanMode] = useState(true);

  const rc = (p,d,c) => { let a = p / 10**d; let i = Math.floor(a); let f = a-i; return ( i.toLocaleString('en-US') + f.toFixed(c).toString().substring(1) )  }
  const rcRock   = (p) =>      {      return  rc( p, props.info.decimals,3).replace(/0+$/,"").replace(/\.$/,"");    }


  function trimmer(s){
    return ( s || '' ).replace( /^[\s'"]+|[\s'"]+$/g, '' );
  }



  const inputChangeHandler = (event) => {
    event.preventDefault();

    let i = event.target.value;


    if (  /^[0-9]*[.]?[0-9]*$/.test(i) || i === "") {
      let f = parseFloat(i);

      setInputValue(i);

      props.inputHandler(f  * 10**props.info.decimals);
    } else {
      setInputValue("");
    }
  };

  const inputAddressChangeHandler = (event) => {

    event.preventDefault();
    let i = trimmer(event.target.value);


    if (/^(ares|shadow)/.test(i)) {
//      setInputAddress(i);
      inputOrderHandler(i);
    }

    if (/^[0-9A-Fa-fx]+$/.test(i) || i === "") {
         setInputAddress(i);

         setInputCode(0x000);
         setInputCurrency(xNullAddress);
         setInputOrder("");

         props.inputAddressHandler(event.target.value);
     }
  };

  const inputOrderHandler = (i) => {
    let p = i.split(':');

    console.log("got ",p);
    if (p.length > 1) {
      if (p[0] === "shadow") {
        setInputAddress(p[1]);

        setInputCode(0x000);
        setInputCurrency(xNullAddress);
        setInputOrder("");

        props.inputAddressHandler(p[1]);
      }
      if (p[0] === "ares") {

        setInputCode(p[1]);
        props.inputOrderHandler(
          p[1],
          base64.decode(p[2]),
          base64.decode(p[3]),
          parseInt(base64.decode(p[4]),16),
          p[5]
        );

        setInputAddress(base64.decode(p[2]));
        setInputCurrency(base64.decode(p[3]));
//        setInputValue(base64.decode(p[4]));
        setInputOrder(p[5]);
      }
    }
  };


  const goMax = () => {
    setInputValue( props.balances[1] );
    props.inputHandler(props.balances[1]  * 10**props.info.decimals);
//    props.inputHandler(props.userBalance);
  };


  const handleResult = (result, error) => {
    if (!!result) {
        inputOrderHandler(result.getText());
    }

    if (!!error) {
//       console.error('HMMM ', error); // logs an error continuously
   }
  }

/*
        71 36 6)"
qrStyle="dots" ecLevel="H" size="200"
fgColor="white" bgColor="rgb(0 0 0 )"

fgColor="rgb(71 36 6)"


&nbsp;&nbsp;&nbsp;
<button
  className={classes.checkButton}
   disabled={ !props.info.isProtected }
   onClick={() => {
     props.checkHandler();
   }}
>
<p>Check</p>
</button>

*/



  return (
    <div className={classes.infologo}>
    <div className={classes.header}>
      <p><br/></p>
    </div>
    <div className={classes.Info}>
      <div >
        <button
          className={classes.scanButton}
           onClick={() => {
             setScanMode( !scanMode );
           }}
        >
        <p>{ scanMode ? "Send" : "Receive" }</p>
        </button>
      </div>

      { scanMode ?
        <div>
          <QRCode
                quietZone="10"
                size="200"
                value={ "shadow:" + props.account }
                eyeRadius={5}
                fgColor="rgba(0,0,0,1)"
          />
          <p>{props.account}</p>
          </div>
       :
        <div>

          <div >
            <div  style={{ width: "60%",  margin: "auto" }}>
              <QrReader
                constraints={{ facingMode: "environment" }}
                delay={300}
                onResult={handleResult}
                showViewFinder={ ViewFinder }
                style={{    "padding-top": "50%" }}
              />
            </div>
          </div>

          <div className={classes.inputDiv}>
            <input style={{top: "-3em", position: "relative"}}
              placeholder="WalletAddress"
              className={classes.input}
              onChange={inputAddressChangeHandler}
              value={inputAddress}
            ></input>
          </div>


          { inputOrder === "" ?

            <div className={classes.goMax}>
               enclosed: <b>{props.balances ? ( props.info.isProtected ? "Some" : rcRock(props.balances[1])) : "n/a" }</b> ARES
                &nbsp;
                <button
                  className={classes.allButton}
                   onClick={goMax}
                >
                  <p>All</p>
                </button>
            </div>
            :
            <div className={classes.goMax}>
                Balance: <b>{props.currencyBalance / 10**props.currencyDecimals }</b>&nbsp;<b>{props.currencySymbol}</b>
                <br/>
                <br/>
                OrderID: <b>{inputOrder}</b>
             </div>
          }


          <div className={classes.inputDiv}>
            <input
              placeholder="Amount"
              className={classes.input}
              onChange={inputChangeHandler}
              value={
                inputOrder === "" ?
                  inputValue
                  :
                  props.currencyValue ? (
                    props.currencyValue / 10**props.currencyDecimals
                  ): ""
              }
            ></input>

          </div>


          <button
            className={classes.digButton}
            disabled={ !props.balances
              || ( props.balances[1] < 1 && !props.info.isProtected && inputOrder === "")
              || ( inputAddress.length<30)
              || ( inputValue > props.balances[1]  / 10**props.info.decimals  && !props.info.isProtected && inputOrder === "")
            }
            onClick={() => {
              props.actionHandler();
              setInputValue('');
              setInputAddress('');
            }}
          >
            <img src={digIcon} alt="dig icon" className={classes.digIcon} />
            <p>{
                   inputOrder === "" ? (
                     inputValue === "" ? "Enter amount" : (
                       ( inputValue > props.balances[xBalance.blocked] / 10**props.info.decimals) ? "too much": "transmit"
                     )
                   ):(
                       props.currencyValue ? (
                         props.currencyValue > props.currencyBalances ? "too much": "pay"
                       ): "..."
                   )
                  }</p>
          </button>

        </div>
      }


      <p><br/></p>
      <div className={classes.box}>
        { inputOrder === "" ?
          <div>
            <div><p>
              You can send ARES to friends.<br/>
              <br/>
              </p>
              <p>
              It is no third party service. You decide when and how much ARES are transfered.
              <br/>
              </p>
            </div>
          </div>
          :
          <div>
            <p>
            (Currency: {inputCurrency},
             Order: {inputOrder},
             Amount: {inputValue}, {props.currencyValue / 10**props.currencyDecimals })
            </p>
          </div>
        }
      </div>
      <div>
      <p><br/><br/><br/></p>
      </div>

    </div>
    </div>
  );
};
//My balance: 504304.394968082 TestToken (Tst)
export default Tx;




export const ViewFinder = () => (
  <>
    <svg
      width="50px"
      viewBox="0 0 100 100"
      style={{
        top: 0,
        left: 0,
        zIndex: 1,
        boxSizing: 'border-box',
        border: '50px solid rgba(0, 0, 0, 0.3)',
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <path
        fill="none"
        d="M13,0 L0,0 L0,13"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      />
      <path
        fill="none"
        d="M0,87 L0,100 L13,100"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      />
      <path
        fill="none"
        d="M87,100 L100,100 L100,87"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      />
      <path
        fill="none"
        d="M100,13 L100,0 87,0"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      />
    </svg>
  </>
);
