import React, { useState } from 'react';
import classes from './Navigation.module.css';



import Tabs from "./Tabs";



const Item = ( props ) => {
  return "";
}


const Navigation = (props) => {
  const [poolStatus, setPoolStatus] = useState('default');

  //console.log("##NAV##",props);

  const changeModul = (val) => {
    if (val === poolStatus) {
    } else {
      setPoolStatus(val);
      props.changePage();
    }
  };
/*
<Item label='UnRock' name='unrock' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
  {props.children[4]}
</Item>
*/
  return (
     <Tabs className={classes.navigation}>
        <Item label='ARES'  enable={true} >
          {window.ethereum /*&& window.ethereum.selectedAddress*/ ?  props.children[0]  : "connect your wallet" }
        </Item>
        <Item label='Dig' name='dig' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[1]}
        </Item>
        <Item label='Vault' name='rock' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[2]}
        </Item>
        <Item label='Tx' name='tx' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[3]}
        </Item>
        <Item label='Earn' name='payout' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[5]}
        </Item>
        <Item label='*' name='info' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
          {window.ethereum /*&& window.ethereum.selectedAddress*/ ?  props.children[6] : "connect your wallet" }
        </Item>
      </Tabs>
  )


};

export default Navigation;
